import React from "react"
import { Link, navigate } from "gatsby"
import { useContext } from "react"
import { LanguageCtx, defaultLang } from "../context"

function LocalizedLink({ to, ...props }) {
    const language = useContext(LanguageCtx)

    const localizedTo =
        language.lang !== defaultLang ? `/${language.lang}/${to}` : to

    return <Link to={localizedTo} {...props} />
}

export default LocalizedLink

export const withLocalizedNavigate = WrappedComponent => props => {
    const language = useContext(LanguageCtx)

    const localizedNavigate = to => {
        const localizedTo =
            language.lang !== defaultLang ? `/${language.lang}/${to}` : to
        navigate(localizedTo)
    }

    return <WrappedComponent navigate={localizedNavigate} {...props} />
}

export const useLocalized = () => {
    const language = useContext(LanguageCtx)

    const localizedNavigate = (to, options) => {
        const localizedTo =
            language.lang !== defaultLang ? `/${language.lang}/${to}` : to
        navigate(localizedTo, options)
    }
    return [localizedNavigate, language.lang]
}
