import React from "react"

function ErrorMsg({ header, body }) {
    return (
        <article className="message is-danger">
            <div className="message-header">
                <p>{header}</p>
            </div>
            <div className="message-body">{body}</div>
        </article>
    )
}

export default ErrorMsg
