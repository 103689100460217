import React from "react"
import { useLocalized } from "../../LocalizedLink"
import { useEffect } from "react"
import { navigate } from "gatsby"
import { css } from "@emotion/core"
import { colors } from "../../../styles/constants"
import { useContext } from "react"
import { usePrevious } from "../../../utils/hooks"
import { deriveNewPathFromLanguage } from "./LanguageSelector.utils"
import { CurrentPathCtx } from "../../../context"

function LanguageSelector({ className, onLanguageChange }) {
    const [_, language] = useLocalized()
    const prevLanguage = usePrevious(language)

    const pathCtx = useContext(CurrentPathCtx)

    useEffect(() => {
        if (pathCtx.path) {
            const newPath = deriveNewPathFromLanguage(
                language,
                prevLanguage,
                pathCtx.path
            )
            if (newPath !== pathCtx.path) {
                navigate(newPath)
            }
        }
    }, [language, pathCtx.path, prevLanguage])

    return (
        <div css={styles.container} className={className}>
            <span
                css={language === "hu" ? styles.active : styles.base}
                onClick={onLanguageChange("hu")}
            >
                hu
            </span>
            <span css={styles.separator}>/</span>
            <span
                css={language === "en" ? styles.active : styles.base}
                onClick={onLanguageChange("en")}
            >
                en
            </span>
        </div>
    )
}

export default LanguageSelector

const styles = {
    container: css`
        color: ${colors.coldGray};
        &:hover {
            cursor: pointer;
        }
    `,
    separator: css`
        padding: 0 0.5rem;
        color: ${colors.coldGray};
    `,
    base: css`
        color: ${colors.gray};
    `,
    active: css`
        font-weight: bold;
        color: ${colors.coldGray};
    `,
}
