import React, { useState } from "react"
import Layout from "../Layout"
import { LanguageCtx } from "../../context"
import { css, Global } from "@emotion/core"
import { colors } from "../../styles/constants"

function LanguageProvider({ children }) {
  const getRedirectLanguage = () => {
    if (typeof navigator === `undefined`) {
      return "hu"
    }

    const lang =
      navigator && navigator.language && navigator.language.split("-")[0]
    if (!lang) return "hu"
    else if (lang !== "hu") return "en"
    else return "hu"
  } // NOTE if you want trasnation, use this function in useState
  const [language, setLanguage] = useState("hu")

  //     const windowGlobal = typeof window !== 'undefined' && window

  // then, windowGlobal.localStorage

  const selectLanguage = selectedLang => {
    if (selectedLang !== language) {
      setLanguage(selectedLang)
    }
  }

  return (
    <LanguageCtx.Provider value={{ lang: language }}>
      <Global
        styles={css`
          * {
            font-family: "Montserrat", sans-serif;
            color: ${colors.primary800};
          }
          h1,
          h2,
          h3,
          h4 {
            font-family: "Dancing Script", cursive;
            color: ${colors.primary800};
          }
        `}
      />
      <Layout selectLanguage={selectLanguage}>{children}</Layout>
      {/* </Global> */}
    </LanguageCtx.Provider>
  )
}

export default ({ element }) => <LanguageProvider>{element}</LanguageProvider>
