import React from "react"
import { css } from "@emotion/core"
import { colors } from "../styles/constants"

function Spinner() {
  return (
    <div
      css={css`
        border: 8px solid #f3f3f3;
        border-radius: 50%;
        border-top: 8px solid ${colors.primary};
        width: 80px;
        height: 80px;
        -webkit-animation: spin 2s linear infinite; /* Safari */
        animation: spin 1s linear infinite;

        /* Safari */
        @-webkit-keyframes spin {
          0% {
            -webkit-transform: rotate(0deg);
          }
          100% {
            -webkit-transform: rotate(360deg);
          }
        }

        @keyframes spin {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      `}
    ></div>
  )
}

export default Spinner
