import React, { Fragment } from "react"
import Spinner from "./Spinner"
import ErrorMsg from "./ErrorMsg"

export const status = {
    IDEAL: "ideal",
    LOADING: "loading",
    SUCCESS: "success",
    FAILURE: "failure",
}

function StatusWrapper({ children, componentStatus, errorBody }) {
    switch (componentStatus) {
        case status.IDEAL:
            return <Fragment>{children}</Fragment>
        case status.LOADING:
            return <Spinner />
        case status.SUCCESS:
            return <Fragment>{children}</Fragment>
        case status.FAILURE:
            return <ErrorMsg header="HIBA!" body={errorBody} />
        default:
            return <Fragment>{children}</Fragment>
    }
}

export default StatusWrapper
