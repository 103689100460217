// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-kezelo-js": () => import("./../../../src/pages/kezelo.js" /* webpackChunkName: "component---src-pages-kezelo-js" */),
  "component---src-templates-around-page-js": () => import("./../../../src/templates/around-page.js" /* webpackChunkName: "component---src-templates-around-page-js" */),
  "component---src-templates-booking-page-js": () => import("./../../../src/templates/booking-page.js" /* webpackChunkName: "component---src-templates-booking-page-js" */),
  "component---src-templates-gallery-page-js": () => import("./../../../src/templates/gallery-page.js" /* webpackChunkName: "component---src-templates-gallery-page-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-story-page-js": () => import("./../../../src/templates/story-page.js" /* webpackChunkName: "component---src-templates-story-page-js" */),
  "component---src-templates-thankyou-page-js": () => import("./../../../src/templates/thankyou-page.js" /* webpackChunkName: "component---src-templates-thankyou-page-js" */)
}

