import React, { useContext, Fragment } from "react"
import { StaticQuery, graphql } from "gatsby"
import Link from "./LocalizedLink"
import facebook from "../images/social/facebook.svg"
import instagram from "../images/social/instagram.svg"
import email from "../images/email_icon.svg"
import call from "../images/call_icon.svg"
import location from "../images/location_icon.svg"
import { css } from "@emotion/core"
import {
  subtitleSize,
  fontSizes,
  desktopMediaQuery,
  colors,
} from "../styles/constants"
import BackgroundImage from "gatsby-background-image"
import LanguageSelector from "./Language/LanguageSelector/LanguageSelector"
import CopyRight from "./CopyRight"
import { LanguageCtx, defaultLang } from "../context"

function Footer({ selectLanguage }) {
  const handleLanguageChange = language => () => {
    selectLanguage(language)
  }

  const { lang } = useContext(LanguageCtx)

  const handleAddressClick = () => {
    if (window && window.open) {
      window.open("https://goo.gl/maps/85mc7E99fGUiMUTb8", "_blank")
    }
  }

  return (
    <StaticQuery
      query={graphql`
        query Footer {
          background: file(relativePath: { eq: "sun.jpg" }) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          allFile(filter: { sourceInstanceName: { eq: "data" } }) {
            edges {
              node {
                childMarkdownRemark {
                  frontmatter {
                    mobileNumber
                    emailAddress
                    address
                  }
                }
              }
            }
          }
        }
      `}
      render={data => {
        const backgroundFluidImageStack = [
          data.background.childImageSharp.fluid,
          `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))`,
        ].reverse()
        const {
          mobileNumber,
          emailAddress,
          address,
        } = data.allFile.edges[0].node.childMarkdownRemark.frontmatter
        return (
          <BackgroundImage
            Tag="div"
            fluid={backgroundFluidImageStack}
            backgroundColor={"black"}
          >
            <footer className="footer has-text-white-ter" css={styles.footer}>
              <div css={styles.content}>
                <ul
                  css={css`
                    ${styles.containerBasics}
                    ${styles.navList}
                  `}
                >
                  <li key={"Főoldal"}>
                    <Link to="/">
                      {lang === defaultLang ? "Főoldal" : "Home"}
                    </Link>
                  </li>
                  <li key="Foglalás">
                    <Link to="/foglalas">
                      {lang === defaultLang ? "Foglalás" : "Booking"}
                    </Link>
                  </li>
                  <li key="Galéria">
                    <Link to="/galeria">
                      {lang === defaultLang ? "Galéria" : "Gallery"}
                    </Link>
                  </li>
                  <li key="Halak a Tiszában">
                    <Link to="/halak">
                      {lang === defaultLang ? "Halak a Tiszában" : "Our Story"}
                    </Link>
                  </li>
                </ul>
                <div
                  css={css`
                    ${styles.containerBasics}
                    ${styles.contacts}
                  `}
                >
                  <h2 className="title" css={styles.contactTitle}>
                    {lang === defaultLang ? "Kapcsolat" : "Contact"}
                  </h2>
                  <ul>
                    <li key="email">
                      <img src={email} alt="email" css={styles.contactIcon} />
                      <span>{emailAddress}</span>
                    </li>
                    <li key="phone">
                      <img src={call} alt="call" css={styles.contactIcon} />
                      <span>{mobileNumber}</span>
                    </li>
                    <li key="street">
                      <img
                        src={location}
                        alt="location"
                        css={styles.contactIcon}
                      />
                      <span css={styles.street} onClick={handleAddressClick}>
                        {address}
                      </span>
                    </li>
                  </ul>
                </div>
                <div
                  css={css`
                    ${styles.containerBasics};
                    ${styles.socialLinks};
                  `}
                >
                  {/* <a
                    title="facebook"
                    href="https://www.facebook.com/eszakcsillagavendeghaz/" //TODO change
                  >
                    <img
                      src={facebook}
                      alt="Facebook"
                      css={styles.socialIcon}
                    />
                  </a> */}
                  {/* <a title="instagram" href="https://instagram.com"> 
                    <img
                      src={instagram}
                      alt="Instagram"
                      css={styles.socialIcon}
                    />
                  </a> */}
                  {/* <LanguageSelector
                    css={styles.languageSelector}
                    onLanguageChange={handleLanguageChange}
                  /> */}
                </div>
                <CopyRight />
              </div>
            </footer>
          </BackgroundImage>
        )
      }}
    />
  )
}

export default Footer

const styles = {
  footer: css`
    ${desktopMediaQuery} {
      display: flex;
      justify-content: center;
    }
  `,
  content: css`
    display: flex;
    flex-wrap: wrap;
    ${desktopMediaQuery} {
      width: 100%;
      max-width: 100rem;
    }
  `,
  containerBasics: css`
    width: 100%;
    padding: 0 0 1rem 1rem;
    ${desktopMediaQuery} {
      width: 33%;
      padding: 0 0 2rem 2rem;
    }
  `,
  navList: css`
    & a {
      font-size: ${fontSizes.default};
      line-height: 1.75;
      text-decoration: inherit;
      color: ${colors.coldGray};
      ${desktopMediaQuery} {
        font-size: ${fontSizes.medium};
      }
    }
  `,
  navElement: css``,
  contacts: css`
    & li {
      font-size: ${fontSizes.default};
      line-height: 1.75;
      display: flex;
      align-items: center;
      color: ${colors.coldGray};
      ${desktopMediaQuery} {
        font-size: ${fontSizes.medium};
      }
    }
    & li span {
      color: ${colors.coldGray};
    }
  `,
  contactTitle: css`
    color: ${colors.coldGray};
    ${subtitleSize};
  `,
  contactIcon: css`
    width: ${fontSizes.medium};
    height: ${fontSizes.medium};
    margin: 0 1rem 0 0;
  `,
  street: css`
    text-decoration: underline;
    &:hover {
      cursor: pointer;
    }
  `,
  socialLinks: css`
    padding: 1rem;
    & a {
      padding: 0.5rem 0.5rem 0.3rem 0.5rem;
      border-radius: 1rem;
      background-color: ${colors.coldGray};
      margin: 0.5rem;
      width: 1rem;
      height: 1rem;
      vertical-align: middle;
      display: inline;
    }
    ${desktopMediaQuery} {
      padding: 2rem;
    }
  `,
  socialIcon: css`
    width: 1rem;
    height: 1rem;
    margin-bottom: 0px;
  `,
  languageSelector: css`
    padding: 1.75rem 0.5rem 0 0.5rem;
  `,
}
