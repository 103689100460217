import React, { useState, useEffect, useContext } from "react"
import LocalizedLink from "../LocalizedLink"
import { css } from "@emotion/core"
import { desktopMediaQuery, colors, sizes } from "../../styles/constants"
import LanguageSelector from "../Language/LanguageSelector/LanguageSelector"
import { LanguageCtx, defaultLang, CurrentPathCtx } from "../../context"
import Logo from "../Logo"
import logoSrc from "../../images/szirenLogo.png"

const Navbar = props => {
  const { lang } = useContext(LanguageCtx)
  const { path } = useContext(CurrentPathCtx)
  const [state, setState] = useState({
    active: false,
    navBarActiveClass: "",
  })

  useEffect(() => {
    setState(s => ({
      ...s,
      navBarActiveClass: s.active ? "is-active" : "",
    }))
  }, [state.active])

  const toggleHamburger = () => {
    // toggle the active boolean in the state
    setState({
      ...state,
      active: !state.active,
    })
  }

  const handleLanguageChange = language => () => {
    props.selectLanguage(language)
  }

  const navElements = [
    { title: { hu: "Főoldal", en: "Home" }, to: "/" },
    { title: { hu: "Foglalás", en: "Booking" }, to: "/foglalas" },
    { title: { hu: "Galéria", en: "Gallery" }, to: "/galeria" },
    { title: { hu: "Halak a Tiszában", en: "Our Story" }, to: "/halak" },
  ]

  return (
    <nav
      className="navbar is-transparent is-fixed-top"
      role="navigation"
      aria-label="main-navigation"
      css={styles.navbar}
    >
      <div className="container">
        <div className="navbar-brand">
          <LocalizedLink to={"/"} css={styles.logo.link}>
            <img css={styles.logo.body} src={logoSrc} />
          </LocalizedLink>
          <div css={styles.logo.placeholder} />

          {/* Hamburger menu */}
          <div
            className={`navbar-burger burger ${state.navBarActiveClass}`}
            data-target="navMenu"
            onClick={toggleHamburger}
            css={css`
              & span {
                color: ${colors.primary300};
              }
            `}
          >
            <span />
            <span />
            <span />
          </div>
        </div>
        <div
          id="navMenu"
          className={`navbar-menu ${state.navBarActiveClass} has-text-centered`}
        >
          <div className="navbar-start has-text-centered">
            {navElements.map(
              element =>
                element.title[lang] && (
                  <LocalizedLink
                    className="navbar-item"
                    to={element.to}
                    css={
                      element.to !== "/" && path?.includes(element.to)
                        ? [styles.navLink, styles.activeNavLink]
                        : styles.navLink
                    }
                    key={element.to}
                    onClick={toggleHamburger}
                  >
                    {element.title[lang].toUpperCase()}
                  </LocalizedLink>
                )
            )}
            {lang === defaultLang && (
              <div className="navbar-item has-dropdown is-hoverable">
                <div css={styles.ddNavigation}>
                  <LocalizedLink
                    className="navbar-item"
                    to="/runak"
                    css={
                      path?.includes("/runak")
                        ? [styles.ddParent, styles.activeNavLink]
                        : styles.ddParent
                    }
                    onClick={toggleHamburger}
                  ></LocalizedLink>
                </div>
              </div>
            )}
          </div>
          <div className="navbar-end">
            {/* <div className="navbar-item">
              <LanguageSelector onLanguageChange={handleLanguageChange} />
            </div> */}
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Navbar

const styles = {
  navbar: css`
    position: absolute !important;
    background-color: transparent;
    background-image: linear-gradient(
      rgba(31, 35, 45, 0.8),
      rgba(31, 35, 45, 0.8)
    );
    & a,
    & .navbar-burger {
      color: white;
    }
    & .navbar-menu {
      background-color: transparent;
    }
    ${desktopMediaQuery} {
      & .navbar-dropdown {
        background-color: transparent;
        background-image: linear-gradient(
          rgba(31, 35, 45, 0.8),
          rgba(31, 35, 45, 0.8)
        );
      }
    }
  `,
  logo: {
    link: css`
      position: absolute;
    `,
    body: css`
      width: 4rem;
      height: 5rem;
      margin: ${sizes.smallScale.xxs};
      ${desktopMediaQuery} {
        width: 8rem;
        height: 10.5rem;
      }
    `,
    placeholder: css`
      width: ${sizes.largeScale.xs};
    `,
  },
  ddNavigation: css`
    display: flex;
    justify-content: center;
  `,
  navLink: css`
    font-size: 1.2rem;
    color: ${colors.primary200} !important;
    ${desktopMediaQuery} {
      margin-bottom: 0.2rem;
      &:hover {
        border-bottom: 0.2rem solid ${colors.primary300};
        margin-bottom: 0px;
      }
    }
  `,
  activeNavLink: css`
    background: linear-gradient(
      rgba(253, 252, 252, 0.2),
      rgba(253, 252, 252, 0.2)
    );
  `,
  ddParent: css`
    font-size: 1.2rem;
    color: ${colors.coldGray} !important;
  `,
  dropdownNavLink: css`
    font-size: 1rem;
    color: ${colors.primary300} !important;
    &:hover {
      color: white !important;
      background-image: linear-gradient(
        rgba(255, 255, 255, 0.4),
        rgba(255, 255, 255, 0.4)
      );
    }
  `,
}
