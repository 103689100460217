import { useRef, useEffect, useReducer } from "react"
import { status } from "../components/StatusWrapper"

export function usePrevious(value, initialValue) {
    const ref = useRef(initialValue)

    useEffect(() => {
        ref.current = value
    }, [value])

    return ref.current
}

export const statusTypes = {
    FETCH: "FETCH",
    RESOLVE: "RESOLVE",
    REJECT: "REJECT",
}

export function useStatusReducer(initData) {
    const [state, dispatch] = useReducer(
        (state, action) => {
            switch (action.type) {
                case statusTypes.FETCH:
                    return {
                        ...state,
                        status: status.LOADING,
                    }
                case statusTypes.RESOLVE:
                    return {
                        data: action.payload,
                        status: status.SUCCESS,
                    }
                case statusTypes.REJECT:
                    return {
                        ...state,
                        status: status.FAILURE,
                    }
                default:
                    return state
            }
        },
        {
            status: status.IDEAL,
            data: initData,
        }
    )
    return [state, dispatch]
}
