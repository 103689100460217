import React, { Fragment, useEffect } from "react"
import { useContext } from "react"
import { CurrentPathCtx } from "../../context"

function GlobalPathTracker({ children, location }) {
    const pathCtx = useContext(CurrentPathCtx)

    useEffect(() => {
        pathCtx.setPath(location.pathname)
    }, [pathCtx, location])

    return <Fragment>{children}</Fragment>
}

export default ({ element, props }) => (
    <GlobalPathTracker {...props}>{element}</GlobalPathTracker>
)
