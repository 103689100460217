import React, { useState } from "react"

import { NavBar } from "./NavBar"
import Footer from "./Footer"
import "./all.scss"
import { CurrentPathCtx } from "../context"
import SEO from "./seo"

export default ({ children, selectLanguage }) => {
  const [currentPath, setCurrentPath] = useState()

  const isRootPath =
    currentPath === "/" || currentPath === "/en" || currentPath === "/en/"

  return (
    <CurrentPathCtx.Provider
      value={{
        path: currentPath,
        setPath: setCurrentPath,
      }}
    >
      <div>
        <SEO />
        <NavBar selectLanguage={selectLanguage} />
        {/* TODO make header, with helmet */}
        <div>{children}</div>
        {(currentPath && isRootPath && (
          <Footer selectLanguage={selectLanguage} />
        )) || <Footer selectLanguage={selectLanguage} />}
      </div>
    </CurrentPathCtx.Provider>
  )
}
