import React from "react"
import { colors, fontSizes, desktopMediaQuery } from "../styles/constants"
import { css } from "@emotion/core"

function CopyRight({ className }) {
  return (
    <div
      css={css`
        display: flex;
        flex-wrap: wrap;
        width: 28rem;
        justify-content: flex-start;
        margin: 1rem 1rem 0.5rem 1rem;
        border-top: 0.5px solid ${colors.coldGray};
        padding-top: 0.5rem;
        width: 100%;
        font-size: ${fontSizes.small};
        color: ${colors.coldGray};
        ${desktopMediaQuery} {
          margin: 1rem 2rem 0.5rem 2rem;
          width: calc(100% - 4rem);
        }
      `}
      className={className}
    >
      <div
        css={css`
          padding-right: 2rem;
          color: ${colors.coldGray};
        `}
      >
        Szirén Vendégház &copy; 2022
      </div>
      {/* <div>
                <span
                    css={css`
                        padding-right: 0.5rem;
                        color: ${colors.coldGray};
                    `}
                >
                    NTAK:. EG20016970
                </span>
            </div> */}
    </div>
  )
}
export default CopyRight
